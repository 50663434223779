<template>
  <div class="page">
    <div class="top"></div>
    <div class="modular record-modular">
      <div class="modular-row">
        <div class="modular-list">
          <div class="modular-list-left">
            <p class="modular-list-title">支付宝</p>
            <p class="modular-list-desc">扫码付款、账单详情</p>
          </div>
        </div>
        <div class="modular-list">
          <div class="modular-list-left">
            <p class="modular-list-title">微信</p>
            <p class="modular-list-desc">扫码支付、账单详情</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>

export default {
  name: 'MagicUse',
  data() {
    return {
      value: false,
    };
  },
  mounted() {
    document.title = '自动记账';
  },
  methods: {
    switchAction(val) {
      console.log(val);
      this.value = val;
    },
  },
};
</script>
<style lang="scss" scoped>
  .page {
    width: 750px;
    min-height: 100%;
    margin: auto;
    background: #F6F6F6;
    font-family: PingFangSC, PingFang SC;
    .top {
      padding-top: 20px;
    }
    .bottom {
      padding-bottom: 20px;
    }
    .modular {
      border-radius: 12px;
      background-color: #ffffff;
      margin-bottom: 40px;
      margin-left: 26px;
      margin-right: 26px;
      &-row {
        margin: 0 20px;
      }
      &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        border-bottom: 2px solid #EAE7E7;
        &:last-child {
          border: none;
        }
        &-title {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          line-height: 40px;
        }
        &-desc {
          font-size: 20px;
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          margin-top: 8px;
        }
      }
    }
  }
</style>
